<template>
  <section >
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Particles animation -->
      <div class="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
        <ParticlesComponent class="absolute inset-0 -z-10" :quantity="5" />
      </div>
      <div class="py-1 md:py-1">
        <div class="overflow-hidden">
          <!-- Carousel built with Swiper.js [https://swiperjs.com/] -->
          <!-- * Custom styles in src/css/additional-styles/theme.scss -->
          <div class="clients-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-slate-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-slate-900">
            <div class="swiper-wrapper !ease-linear select-none items-center">
              <!-- Carousel items -->
              <div class="swiper-slide !w-auto" v-for="(client, index) in clients" :key="index"> 
                <img :src="client.path" :alt="client.name" :width="client.width" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive } from 'vue'
import ParticlesComponent from './ParticlesComponent.vue'

// Import Swiper
import Swiper, { Autoplay } from 'swiper'
import 'swiper/css'

Swiper.use([Autoplay])

export default {
  name: 'ClientsSection',
  components: {
    ParticlesComponent
  },  
  setup() {
    const clients = reactive([
      {path:require('@/assets/images/clients/C-Risk 1.png'), name:"c-risk", width:80},
      {path:require('@/assets/images/clients/dogpatchlabs 1.png'), name:"dogpatchlabs",width:150 },
      {path:require('@/assets/images/clients/web-CyberIreland 2.png'), name:"cyber ireland", width: 160},
      // {path:require('@/assets/images/clients/web-DirectLineGroup 1.png'), name:"direct line group", width:140},
      {path:require('@/assets/images/clients/Spire-logo.png'), name:"direct line group", width:140},
      {path:require('@/assets/images/clients/web-Growing-Capital 1.png'), name:"growing capital", width:155},
      {path:require('@/assets/images/clients/web-Station-F 1.png'), name:"station f",width:160},
    ]);
    onMounted(() => {
      const carousel = new Swiper('.clients-carousel', {
        slidesPerView: 'auto',
        spaceBetween: 64,
        centeredSlides: true,
        loop: true,
        speed: 5000,
        noSwiping: true,
        noSwipingClass: 'swiper-slide',
        autoplay: {
          delay: 0,
          disableOnInteraction: true,
        },
      })
    })

    return {clients}
  }  
}
</script>