<template>
  <section>
    <div class="max-w-3xl mx-auto px-4 sm:px-6">
      <div class="relative pb-12 md:pb-20">

        <!-- Particles animation -->
        <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-80 -mt-6">
          <ParticlesComponent class="absolute inset-0 -z-10" :quantity="10" :staticity="30" />
        </div>

        <!-- Carousel -->
        <div class="text-center">
          <!-- Testimonial image -->
          <div class="relative h-32 [mask-image:_linear-gradient(0deg,transparent,theme(colors.white)_40%,theme(colors.white))]">
            <transition-group
              tag="div"
              class="absolute top-0 left-1/2 -translate-x-1/2 w-[480px] h-[480px] -z-10 pointer-events-none before:rounded-full rounded-full before:absolute before:inset-0 before:bg-gradient-to-b before:from-slate-400/20 before:to-transparent before:to-20% after:rounded-full after:absolute after:inset-0 after:bg-slate-900 after:m-px before:-z-20 after:-z-20"
              enter-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
              enter-from-class="opacity-0 -rotate-[60deg]"
              enter-to-class="opacity-100 rotate-0"
              leave-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700"
              leave-from-class="opacity-100 rotate-0"
              leave-to-class="opacity-0 rotate-[60deg]"
            >               
              <template :key="index" v-for="(item, index) in items">
                <div v-show="active === index" class="absolute inset-0 h-full -z-10">
                  <img class="relative top-11 left-1/2 -translate-x-1/2 rounded-full" :src="item.img" width="56" height="56" :alt="item.name">
                </div>
              </template>
            </transition-group>
          </div>
          <!-- Text -->
          <div class="mb-10">
            <transition-group
              tag="div"
              class="relative flex flex-col"
              enter-active-class="transition ease-in-out duration-500 delay-200 order-first"
              enter-from-class="opacity-0 -translate-x-4"
              enter-to-class="opacity-100 translate-x-0"
              leave-active-class="transition ease-out duration-300 delay-300 absolute"
              leave-from-class="opacity-100 translate-x-0"
              leave-to-class="opacity-0 translate-x-4"
            >              
              <template :key="index" v-for="(item, index) in $tm('testmonials_items')">
                <div v-show="active === index">
                  <div class="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">{{ item.quote }}</div>
                </div>
              </template>
            </transition-group>
          </div>
          <!-- Buttons -->
          <div class=" justify-center -m-1.5 grid md:grid-cols-3 gap-8 md:gap-4">
            <template :key="index" v-for="(item, index) in $tm('testmonials_items')">
              <button class="btn-sm !whitespace-normal m-1.5 !text-xs py-1.5 text-slate-400 transition duration-150 ease-in-out [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none h-auto" :class="active === index ? 'opacity-100' : 'opacity-30 hover:opacity-60'" @click="active = index; stopAutorotate();">
                <span class="relative text-xs">
                  <span class="text-slate-50">{{ item.name }}</span> <span class="text-slate-600">-</span> <span>{{ item.role }}</span>
                </span>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import ParticlesComponent from './ParticlesComponent.vue'
import TestimonialImg01 from '@/assets/images/Testimonials_0001_Tom.png'
import TestimonialImg02 from '@/assets/images/Testimonials_0000_Diane.png'
import TestimonialImg03 from '@/assets/images/Testimonials_0002_Nick.png'

export default {
  name: 'TestimonialsSection',
  components: {
    ParticlesComponent
  },
  setup() {
    const active = ref(0)
    const autorotate = ref(true)
    const autorotateTiming = ref(7000)
    const items = [
      {
        img: TestimonialImg01,
        quote: "Protostars.ai has helped C-Risk streamline operations in a secure way.",
        name: 'Tom Callaghan',
        role: 'CEO C-Risk Cyber Risk Quantification FAIR™'
      },
      {
        img: TestimonialImg02,
        quote: "It was the best decision we have ever made. The tech (protostars.ai) is on another level.",
        name: 'Diane Courtney',
        role: 'Solicitor & CEO of DoOrder'
      },
      {
        img: TestimonialImg03,
        quote: "I stopped waking up in the night wondering if something was done. (AI automation)",
        name: 'Nick Batchelor',
        role: 'IT Director & Business Advisor'
      }
    ]

    const stopAutorotate = () => {
      clearInterval(autorotateInterval.value)
    }

    let autorotateInterval = ref(null)

    onMounted(() => {
      if (autorotate.value) {
        autorotateInterval.value = setInterval(() => {
          active.value = active.value + 1 === items.length ? 0 : active.value + 1
        }, autorotateTiming.value)
      }
    })

    onBeforeUnmount(() => {
      stopAutorotate()
    })

    return {
      active,
      autorotate,
      autorotateTiming,
      items,
      stopAutorotate
    }
  }
}

</script>