<template>
  <section>
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Particles animation -->
      <ParticlesComponent class="absolute inset-0 -z-10" />

      <!-- Illustration -->
      <div class="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden"
        aria-hidden="true">
        <div class="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
          <img src="@/assets/images/stellar/glow-bottom-blue.svg" class="max-w-none" width="2146" height="774"
            alt="Hero Illustration">
        </div>
      </div>

      <div class="pt-32 pb-16 md:pt-52 md:pb-32">

        <!-- Hero content -->
        <div class="max-w-4xl mx-auto text-center">
          <div class="mb-6" data-aos="fade-down">
            <div class="inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md">
              <a class="btn-sm py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box]  relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                href="#0">
                <span class="relative inline-flex items-center">
                  {{$t('link_protostars_now_alpha')}} <span
                    class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon icon="fa-solid fa-arrow-right" /></span>
                </span>
              </a>
            </div>
          </div>
          <h1
            class="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4"
            data-aos="fade-down">{{$t('hero_title')}}</h1>
          <p class="text-lg text-slate-300 mb-8" data-aos="fade-down" data-aos-delay="200">
            <span class="w-[862px] text-center" v-html=" $t('hero_text')"></span>
          </p>
          <div
            class=" max-w-xs mx-auto sm:max-w-none sm:inline-flex sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4"
            data-aos="fade-down" data-aos-delay="400">
            <div>
              <a class="btn text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white hover:no-underline w-full transition duration-150 ease-in-out group"
                href="/book_demo">
                 {{$t('btn_book_demo')}}<span
                  class="tracking-normal text-cyan-500 group-hover:translate-x-0.5  transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon icon="fa-solid fa-arrow-right" /></span>
              </a>
            </div>
            <!-- <div>
              <a class="btn text-slate-200 hover:text-white bg-slate-900 bg-opacity-25 hover:bg-opacity-30 w-full transition duration-150 ease-in-out" href="#0">
                <svg class="shrink-0 fill-slate-300 mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                  <path d="m1.999 0 1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 0l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 10l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM6.292 7.586l2.646-2.647L11.06 7.06 8.413 9.707zM0 13.878l5.586-5.586 2.122 2.121L2.12 16z" />
                </svg>
                <span>Read the docs</span>
              </a>
            </div> -->
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ParticlesComponent from './ParticlesComponent.vue'

export default {
  name: 'HeroSection',
  components: {
    ParticlesComponent
  }
}
</script>