<template>
  <!-- <div v-if="!$route.fullPath.includes('login')">
    <Navbar />
    <div class="home-page">

      <div class="content">

        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <Footer />
  </div>
  <div v-else>

    <router-view :key="$route.fullPath" />
  </div> -->
  <router-view :key="$route.fullPath"/>
</template>

<script>

// import Navbar from '@/components/layout/Navbar.vue';
// import HomeLandPage from './views/HomeLandPage.vue'
// import Footer from './components/layout/Footer.vue';
import $ from 'jquery'

import AOS from 'aos'

export default {
  name: 'App',
  components: {
    // Navbar, Footer
  },
  mounted(){
    AOS.init({
      once: true,
      duration: 500,
      easing: 'ease-out-cubic',
    })
  },
  updated() {
    console.log("app ",this.$route)
    if (this.$route.hash) {
      $('html, body').animate({
        scrollTop: $(this.$route.hash).offset().top - 90
      });
    } else {
      $('html, body').animate({
        scrollTop: 0
      });
    }
  }
}
</script>

<style>

@import './../node_modules/@vueform/vueform/dist/tailwind-material.css';
</style>
