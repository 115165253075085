<template>
  <div class="relative h-full  rounded-3xl p-px before:absolute before:w-full md:before:w-96 before:h-96 before:-left-48 before:-top-48 before:bg-cyan-500 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 before:blur-[100px] after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.slate.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden"
  :class="background">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HighlighterItemComponent',
  props:{
    background:{
      default: 'bg-slate-800'
    }
  }
}
</script>
