<template>
  <section class="relative">

    <!-- Blurred shape -->
    <div class="absolute top- -translate-y-1/4 left-1/2 -translate-x-1/2 blur-2xl opacity-50 pointer-events-none -z-10" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
        <defs>
          <linearGradient id="bs3-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
            <stop offset="0%" stop-color="#55DAF7" />
            <stop offset="100%" stop-color="#55DAF7" stop-opacity="0" />
          </linearGradient>
        </defs>
        <path fill="url(#bs3-a)" fill-rule="evenodd" d="m410 0 461 369-284 58z" transform="matrix(1 0 0 -1 -410 427)" />
      </svg>
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-16 pb-12 md:pt-32 md:pb-20 border-b border-slate-800">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 class="h2  font-extrabold text-5xl leading-normal bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">{{$t('features3_title')}}</h2>
          <p class="text-lg/normal  text-slate-400">{{ $t('features3_text') }}</p>
        </div>

        <div class="max-w-3xl mx-auto">
          <div data-aos="fade-down">
            <!-- <HighlighterComponent class="group" :background="''">
              <HighlighterItemComponent> -->
                <div class="relative h-full bg-none rounded-[inherit] z-20 overflow-hidden">
                  <!-- Radial gradient -->
                  <!-- <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                    <div class="absolute inset-0 translate-z-0 bg-cyan-500 rounded-full blur-[120px]"></div>
                  </div> -->

                  <img src="@/assets/images/features/feature-image-04.png" width="768" height="400" alt="Feature 04">
                </div>
              <!-- </HighlighterItemComponent>
            </HighlighterComponent> -->
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// import HighlighterComponent from './HighlighterComponent.vue';
// import HighlighterItemComponent from './HighlighterItemComponent.vue';

export default {
  name: 'FeaturesSection03',
  components: {
    // HighlighterComponent,
    // HighlighterItemComponent,
  },  
}
</script>