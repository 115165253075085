<template>
    <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
        <!-- Site header -->
        <HeaderSection />

        <!-- Page content -->
        <main class="grow">
            <HeroSection />
            <ClientsSection id="clients" />
            <FeaturesSection />
            <FeaturesSection02 />
            <FeaturesSection03 />
             <TestimonialsCarouselSection />
             <FeaturesSection04 />
            <!-- <PricingSection id="pricing"/> -->
            <TestimonialsSection />
            <CtaSection />
            <ContactUsSection id="contactus"/>
        </main>

        <FooterSection />
    </div>
</template>

<script>
import HeaderSection from '@/partials/HeaderSection.vue'
import HeroSection from '@/partials/HeroSection.vue'
import ClientsSection from '@/partials/ClientsSection.vue'
import FeaturesSection from '@/partials/FeaturesSection.vue'
import FeaturesSection02 from '@/partials/FeaturesSection02.vue'
import FeaturesSection03 from '@/partials/FeaturesSection03.vue'
import TestimonialsCarouselSection from '@/partials/TestimonialsCarouselSection.vue'
import FeaturesSection04 from '@/partials/FeaturesSection04.vue'
// import PricingSection from '@/partials/PricingSection.vue'
import TestimonialsSection from '@/partials/TestimonialsSection.vue'
import CtaSection from '@/partials/CtaSection.vue'
import ContactUsSection from '@/partials/ContactUsSection.vue'
import FooterSection from '@/partials/FooterSection.vue'

export default {
    name: 'HomeLandPage',
    components: {
        HeaderSection,
        HeroSection,
        ClientsSection, FeaturesSection,
        FeaturesSection02, 
        FeaturesSection03,
        TestimonialsCarouselSection, 
        FeaturesSection04,
        // PricingSection, 
        TestimonialsSection,
        CtaSection, 
        ContactUsSection, 
        FooterSection
    },
    data() {
        return {
        }
    },
    mounted() {
        console.log("mounted home page")
    }

}
</script>

<style></style>