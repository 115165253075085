<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-12 md:pt-20">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <div>
            <div class="inline-flex text-base font-medium bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-cyan-200 pb-3">{{$t('testmonials_subtitle')}}</div>
          </div>
          <h2 class="h2 text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">{{$t('testmonials_title')}}</h2>
          <p class="text-lg font-normal text-slate-400">{{ $t('testmonials_text') }}</p>
        </div>

        <!-- Carousel built with Swiper.js [https://swiperjs.com/] -->
        <!-- * Custom styles in src/css/additional-styles/theme.scss -->
        <div class="relative before:absolute before:inset-0 before:-translate-x-full before:z-20 before:bg-gradient-to-l before:from-transparent before:to-slate-900 before:to-20% after:absolute after:inset-0 after:translate-x-full after:z-20 after:bg-gradient-to-r after:from-transparent after:to-slate-900 after:to-20%">
          <div class="stellar-carousel swiper-container group">
            <HighlighterComponent class="swiper-wrapper w-fit" :refresh="swiperInitialized">
              <!-- Carousel items -->
              <HighlighterItemComponent class="swiper-slide h-auto group/slide">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <ParticlesComponent class="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out" :quantity="3" :refresh="swiperInitialized" />
                  <!-- Radial gradient -->
                  <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                    <div class="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-cyan-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                  </div>
                  <div class="flex flex-col p-6 h-full">
                    <img class="mb-3" src="@/assets/images/testmonials/carousel-icon-01.svg" width="56" height="56" alt="Icon 01">
                    <div class="grow">
                      <div class="font-bold text-lg mb-1">{{$t('testmonials_list_item1_title')}}</div>
                      <div class="text-slate-400 mb-3">{{$t('testmonials_list_item1_text')}}</div>
                    </div>
                    <div class="text-right">
                      <router-link class="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" to="">{{$t('link_more_soon')}}  <span class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"> <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link>
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
              <HighlighterItemComponent class="swiper-slide h-auto group/slide">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <ParticlesComponent class="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out" :quantity="3" :refresh="swiperInitialized" />
                  <!-- Radial gradient -->
                  <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                    <div class="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-cyan-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                  </div>
                  <div class="flex flex-col p-6 h-full">
                    <img class="mb-3" src="@/assets/images/testmonials/carousel-icon-02.svg" width="56" height="56" alt="Icon 01">
                    <div class="grow">
                      <div class="font-bold text-lg mb-1">{{$t('testmonials_list_item2_title')}}</div>
                      <div class="text-slate-400 mb-3">{{$t('testmonials_list_item2_text')}}</div>
                    </div>
                    <div class="text-right">
                      <router-link class="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" to="">{{$t('link_more_soon')}}  <span class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"> <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link>
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
              <HighlighterItemComponent class="swiper-slide h-auto group/slide">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <ParticlesComponent class="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out" :quantity="3" :refresh="swiperInitialized" />
                  <!-- Radial gradient -->
                  <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                    <div class="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-cyan-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                  </div>
                  <div class="flex flex-col p-6 h-full">
                    <img class="mb-3" src="@/assets/images/testmonials/carousel-icon-03.svg" width="56" height="56" alt="Icon 01">
                    <div class="grow">
                      <div class="font-bold text-lg mb-1">{{$t('testmonials_list_item3_title')}}</div>
                      <div class="text-slate-400 mb-3">{{$t('testmonials_list_item3_text')}}</div>
                    </div>
                    <div class="text-right">
                      <router-link class="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" to="">{{$t('link_more_soon')}}  <span class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"> <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link>
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
              <HighlighterItemComponent class="swiper-slide h-auto group/slide">
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <!-- Particles animation -->
                  <ParticlesComponent class="absolute inset-0 -z-10 opacity-0 group-[.swiper-slide-active]/slide:opacity-100 group-hover/slide:opacity-100 transition-opacity duration-500 ease-in-out" :quantity="3" :refresh="swiperInitialized" />
                  <!-- Radial gradient -->
                  <div class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/3 aspect-square" aria-hidden="true">
                    <div class="absolute inset-0 translate-z-0 rounded-full bg-slate-800 group-[.swiper-slide-active]/slide:bg-cyan-500 transition-colors duration-500 ease-in-out blur-[60px]"></div>
                  </div>
                  <div class="flex flex-col p-6 h-full">
                    <img class="mb-3" src="@/assets/images/testmonials/carousel-icon-04.svg" width="56" height="56" alt="Icon 01">
                    <div class="grow">
                      <div class="font-bold text-lg mb-1">{{$t('testmonials_list_item4_title')}}</div>
                      <div class="text-slate-400 mb-3">{{$t('testmonials_list_item4_text')}}</div>
                    </div>
                    <div class="text-right">
                      <router-link class="text-sm font-medium text-slate-300 hover:text-white inline-flex items-center transition duration-150 ease-in-out group" to="">{{$t('link_more_soon')}}  <span class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"> <font-awesome-icon icon="fa-solid fa-arrow-right" /></span></router-link>
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
             
            </HighlighterComponent>
          </div>
        </div>

        <!-- Arrows -->
        <div class="flex mt-8 justify-end">
          <button class="carousel-prev relative z-20 w-12 h-12 flex items-center justify-center group">
            <span class="sr-only">Previous</span>
            <svg class="w-4 h-4 fill-slate-500 group-hover:fill-cyan-500 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
            </svg>
          </button>
          <button class="carousel-next relative z-20 w-12 h-12 flex items-center justify-center group">
            <span class="sr-only">Next</span>
            <svg class="w-4 h-4 fill-slate-500 group-hover:fill-cyan-500 transition duration-150 ease-in-out" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
            </svg>
          </button>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import ParticlesComponent from './ParticlesComponent.vue'
import HighlighterComponent from './HighlighterComponent.vue'
import HighlighterItemComponent from './HighlighterItemComponent.vue'

// Import Swiper
import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
Swiper.use([Navigation])

export default {
  name: 'TestimonialsCarouselSection',
  components: {
    ParticlesComponent,
    HighlighterComponent,
    HighlighterItemComponent,
  },
  setup() {

    const swiperInitialized = ref(false)

    onMounted(() => {
      const carousel = new Swiper('.stellar-carousel', {
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        },
        grabCursor: true,
        loop: false,
        centeredSlides: false,
        initialSlide: 0,
        spaceBetween: 24,
        navigation: {
          nextEl: '.carousel-next',
          prevEl: '.carousel-prev',
        },
      })
      swiperInitialized.value = true
    })

    return {
      swiperInitialized,
    }    
  }  
}
</script>