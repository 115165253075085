import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/dist/tailwind'
import { defineConfig } from '@vueform/vueform'

export default defineConfig({
    theme: tailwind,
    locales: { en },
    locale: 'en',


    removeClass: {
        TextElement: {
            // input: ["with-floating:form-p-input-floating "],
            // inputContainer: ["form-radius-large with-floating:form-p-input-floating form-bg-input-success form-color-input-success form-border-color-input-success hover:form-shadow-input-hover focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover"]
            inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
              "focused:form-shadow-input-focus", "focused:form-ring",
              "focused-hover:form-shadow-input-hover"],
            inputContainer_focused: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
                "focused:form-shadow-input-focus", "focused:form-ring",
                "focused-hover:form-shadow-input-hover"],
            // input_focused: ["form-color-input-focus", "form-autofill-focus"]

        },
        TextareaElement: {
            // input: ["with-floating:form-p-input-floating "],
            // inputContainer: ["form-radius-large", "with-floating:form-p-input-floating", "form-bg-input-success", "form-color-input-success", "form-border-color-input-success", "fhover:form-shadow-input-hover", "ffocused:form-shadow-input-focus", "ffocused:form-ring", "ffocused-hover:form-shadow-input-hover"]
            // inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
            //   "focused:form-shadow-input-focus", "focused:form-ring",
            //   "focused-hover:form-shadow-input-hover"],
            // inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
            //   "form-shadow-input-focus", "form-ring",
            //   "form-shadow-input-hover"],
            // input_focused: ["form-color-input-focus", "form-autofill-focus"]

        },
    }
})