<template>
  <section>
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Illustration -->
      <div class="absolute inset-0 -z-10 -mx-28 rounded-t-[3rem] pointer-events-none overflow-hidden"
        aria-hidden="true">
        <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10">
          <img src="@/assets/images/stellar/glow-top-blue.svg" class="max-w-none" width="1404" height="658"
            alt="Features Illustration">
        </div>
      </div>

      <div class="pt-16 pb-12 md:pt-52 md:pb-20">

        <div>

          <!-- Section content -->
          <div
            class="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:space-x-8 lg:space-x-16 xl:space-x-20 space-y-8 space-y-reverse md:space-y-0">

            <!-- Content -->
            <div class="md:w-7/12 lg:w-1/2 order-1 md:order-none max-md:text-center" data-aos="fade-down">
              <!-- Content #1 -->
              <div>
                <div
                  class="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-cyan-200 pb-3">
                  {{$t('features1_title')}} </div>
              </div>
              <h3
                class="h3 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-3">
                {{$t('features1_subtitle')}} -  <br />
                {{$t('features1_subtitle2')}} </h3>


              <p class="text-lg text-slate-400 mb-8" v-html="$t('features1_text')"> </p>
              <div class="mt-8 max-w-xs max-md:mx-auto space-y-2">
                <button
                  class="flex items-center text-sm/normal text-left font-medium text-slate-50 rounded  bg-slate-800/25 w-full  transition duration-150 ease-in-out hover:opacity-100"
                  :class="tab !== '1' ? 'border border-slate-700 opacity-50' : 'border-none bg-gradient-to-r from-green-500 to-cyan-500 text-white  font-semibold rounded p-[1px] shadow shadow-cyan-500/25'"
                  @click.prevent="tab = '1'">
                  <span class="flex items-center w-full bg-slate-800 text-slate-50 rounded px-3 py-2">
                    <svg class="shrink-0 fill-slate-300 mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                      <path
                        d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12Zm0 14V2H2v12h12Zm-3-7H5a1 1 0 1 1 0-2h6a1 1 0 0 1 0 2Zm0 4H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span>{{$t('features1_list_item1')}}</span>
                  </span>
                </button>
                <button
                  class="flex items-center text-sm/normal text-left font-medium text-slate-50 rounded  bg-slate-800/25 w-full  transition duration-150 ease-in-out hover:opacity-100"
                  :class="tab !== '2' ? 'border border-slate-700 opacity-50' : 'border-none bg-gradient-to-r from-green-500 to-cyan-500 text-white  font-semibold rounded p-[1px] shadow shadow-cyan-500/25'"
                  @click.prevent="tab = '2'">
                  <span class="flex items-center w-full bg-slate-800 text-slate-50 rounded px-3 py-2">
                    <svg class="shrink-0 fill-slate-300 mr-3"  xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" >
                      <path d="M11 2L1 2.019C0.448 2.019 0 1.571 0 1.019C0 0.467 0.448 0.019 1 0.019L11 0V2Z"
                         />
                      <path d="M15 7H5V5H15C15.552 5 16 5.448 16 6C16 6.552 15.552 7 15 7Z"  />
                      <path d="M15 2.019L13 2V0L15 0.019C15.552 0.019 16 0.467 16 1.019C16 1.572 15.552 2.019 15 2.019Z"
                         />
                      <path d="M11 12H1C0.448 12 0 11.552 0 11C0 10.448 0.448 10 1 10H11V12Z"  />
                      <path d="M15 12H13V10H15C15.552 10 16 10.448 16 11C16 11.552 15.552 12 15 12Z"  />
                      <path d="M3 7H1C0.448 7 0 6.552 0 6C0 5.448 0.448 5 1 5H3V7Z"  />
                    </svg>
                    <span>{{$t('features1_list_item2')}} </span>
                  </span>
                </button>
                <button
                  class="flex items-center text-sm/normal text-left font-medium text-slate-50 rounded  bg-slate-800/25 w-full transition duration-150 ease-in-out hover:opacity-100"
                  :class="tab !== '3' ? 'border border-slate-700 opacity-50' : 'border-none bg-gradient-to-r from-green-500 to-cyan-500 text-white  font-semibold rounded p-[1px] shadow shadow-cyan-500/25'"
                  @click.prevent="tab = '3'">
                  <span class="flex items-center w-full bg-slate-800 text-slate-50 rounded px-3 py-2">
                    <svg class="shrink-0 fill-slate-300 mr-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                      <path
                        d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8ZM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1Z" />
                    </svg>
                    <span>{{$t('features1_list_item3')}}</span>
                  </span>
                </button>
              </div>
            </div>

            <!-- Image -->
            <div class="md:w-5/12 lg:w-1/2" data-aos="fade-up" data-aos-delay="100">
              <div class="relative py-24 -mt-12">

                <!-- Particles animation -->
                <ParticlesComponent class="absolute inset-0 -z-10" :quantity="8" :staticity="30" />

                <div class="flex items-center justify-center">
                  <div class="relative w-48 h-48 flex justify-center items-center">
                    <!-- Halo effect -->
                    <svg
                      class="absolute inset-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 will-change-transform pointer-events-none blur-md"
                      width="480" height="480" viewBox="0 0 480 480" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <linearGradient id="pulse-a" x1="50%" x2="50%" y1="100%" y2="0%">
                          <stop offset="0%" stop-color="#55f7f7" />
                          <stop offset="76.382%" stop-color="#faf5ff" />
                          <stop offset="100%" stop-color="#6366f1" />
                        </linearGradient>
                      </defs>
                      <g fill-rule="evenodd">
                        <path class="pulse" fill="url(#pulse-a)" fill-rule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z">
                        </path>
                        <path class="pulse pulse-1" fill="url(#pulse-a)" fill-rule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z">
                        </path>
                        <path class="pulse pulse-2" fill="url(#pulse-a)" fill-rule="evenodd"
                          d="M240,0 C372.5484,0 480,107.4516 480,240 C480,372.5484 372.5484,480 240,480 C107.4516,480 0,372.5484 0,240 C0,107.4516 107.4516,0 240,0 Z M240,88.8 C156.4944,88.8 88.8,156.4944 88.8,240 C88.8,323.5056 156.4944,391.2 240,391.2 C323.5056,391.2 391.2,323.5056 391.2,240 C391.2,156.4944 323.5056,88.8 240,88.8 Z">
                        </path>
                      </g>
                    </svg>
                    <!-- Grid -->
                    <div
                      class="absolute inset-0 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none w-[500px] h-[500px] rounded-full overflow-hidden [mask-image:_radial-gradient(black,_transparent_60%)]">
                      <div class="h-[200%] animate-endless">
                        <div
                          class="absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20">
                        </div>
                        <div
                          class="absolute inset-0 [background:_repeating-linear-gradient(transparent,_transparent_48px,_theme(colors.cyan.500)_48px,_theme(colors.cyan.500)_49px)]">
                        </div>
                        <div
                          class="absolute inset-0 [background:_repeating-linear-gradient(90deg,transparent,_transparent_48px,_theme(colors.white)_48px,_theme(colors.white)_49px)] blur-[2px] opacity-20">
                        </div>
                        <div
                          class="absolute inset-0 [background:_repeating-linear-gradient(90deg,transparent,_transparent_48px,_theme(colors.cyan.500)_48px,_theme(colors.cyan.500)_49px)]">
                        </div>
                      </div>
                    </div>
                    <!-- Icons -->
                    <transition
                      enter-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                      enter-from-class="opacity-0 -rotate-[60deg]" enter-to-class="opacity-100 rotate-0"
                      leave-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 absolute"
                      leave-from-class="opacity-100 rotate-0" leave-to-class="opacity-0 rotate-[60deg]">
                      <div class="absolute" v-show="tab === '1'">
                        <div
                          class="relative flex items-center justify-center w-16 h-16 border border-transparent rounded-2xl shadow-2xl -rotate-[14deg] [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                          <svg class="relative fill-slate-200" xmlns="http://www.w3.org/2000/svg" width="23"
                            height="25">
                            <path fill-rule="nonzero"
                              d="M10.55 15.91H.442L14.153.826 12.856 9.91h10.107L9.253 24.991l1.297-9.082Zm.702-8.919L4.963 13.91h7.893l-.703 4.918 6.289-6.918H10.55l.702-4.918Z" />
                          </svg>
                        </div>
                      </div>
                    </transition>
                    <transition
                      enter-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                      enter-from-class="opacity-0 -rotate-[60deg]" enter-to-class="opacity-100 rotate-0"
                      leave-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 absolute"
                      leave-from-class="opacity-100 rotate-0" leave-to-class="opacity-0 rotate-[60deg]">
                      <div class="absolute" v-show="tab === '2'">
                        <div
                          class="relative flex items-center justify-center w-16 h-16 border border-transparent rounded-2xl shadow-2xl -rotate-[14deg] [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                          <svg class="relative fill-slate-200" xmlns="http://www.w3.org/2000/svg" width="22"
                            height="22">
                            <path
                              d="M18 14h-2V8h2c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4v2H8V4c0-2.2-1.8-4-4-4S0 1.8 0 4s1.8 4 4 4h2v6H4c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4v-2h6v2c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4ZM16 4c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2h-2V4ZM2 4c0-1.1.9-2 2-2s2 .9 2 2v2H4c-1.1 0-2-.9-2-2Zm4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2h2v2ZM8 8h6v6H8V8Zm10 12c-1.1 0-2-.9-2-2v-2h2c1.1 0 2 .9 2 2s-.9 2-2 2Z" />
                          </svg>
                        </div>
                      </div>
                    </transition>
                    <transition
                      enter-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 order-first"
                      enter-from-class="opacity-0 -rotate-[60deg]" enter-to-class="opacity-100 rotate-0"
                      leave-active-class="transition ease-[cubic-bezier(0.68,-0.3,0.32,1)] duration-700 absolute"
                      leave-from-class="opacity-100 rotate-0" leave-to-class="opacity-0 rotate-[60deg]">
                      <div class="absolute" v-show="tab === '3'">
                        <div
                          class="relative flex items-center justify-center w-16 h-16 border border-transparent rounded-2xl shadow-2xl -rotate-[14deg] [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                          <svg class="relative fill-slate-200" xmlns="http://www.w3.org/2000/svg" width="26"
                            height="14">
                            <path fill-rule="nonzero" d="m10 5.414-8 8L.586 12 10 2.586l6 6 8-8L25.414 2 16 11.414z" />
                          </svg>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue'
import ParticlesComponent from './ParticlesComponent.vue'

export default {
  name: 'FeaturesSection',
  components: {
    ParticlesComponent
  },
  setup() {

    const tab = ref('1')

    return {
      tab,
    }
  },
}
</script>