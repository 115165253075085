<template>
  <section class="relative">

    <!-- Particles animation -->
    <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10 w-80 h-80 -mt-24 -ml-32">
      <ParticlesComponent class="absolute inset-0 -z-10" :quantity="6" :staticity="30" />
    </div>

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-16 md:pt-32">

        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2
            class="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4">
            {{ $t('features2_title') }}</h2>
          <p class="text-lg text-slate-400"> {{ $t('features2_text') }} </p>
        </div>

        <!-- Highlighted boxes -->
        <div class="relative pb-12 md:pb-20">
          <!-- Blurred shape -->
          <div class="absolute bottom-0 -mb-20 left-1/2 -translate-x-1/2 blur-2xl opacity-50 pointer-events-none"
            aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" width="434" height="427">
              <defs>
                <linearGradient id="bs2-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                  <stop offset="0%" stop-color="#55DAF7" />
                  <stop offset="100%" stop-color="#55DAF7" stop-opacity="0" />
                </linearGradient>
              </defs>
              <path fill="url(#bs2-a)" fill-rule="evenodd" d="m346 898 461 369-284 58z"
                transform="translate(-346 -898)" />
            </svg>
          </div>
          <!-- Grid -->
          <HighlighterComponent class="grid md:grid-cols-12 gap-6 group ">
            <!-- Box #1 -->
            <div class="md:col-span-12 mb-8" data-aos="fade-down">
              <HighlighterItemComponent>
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <div class="flex flex-col lg:flex-row  md:justify-between">
                    <!-- Blurred shape -->
                    <div class="absolute right-0 top-0 blur-2xl " aria-hidden="true">
                      <svg xmlns="http://www.w3.org/2000/svg" width="342" height="393">
                        <defs>
                          <linearGradient id="bs-a" x1="19.609%" x2="50%" y1="14.544%" y2="100%">
                            <stop offset="0%" stop-color="#55DAF7" />
                            <stop offset="100%" stop-color="#55DAF7" stop-opacity="0" />
                          </linearGradient>
                        </defs>
                        <path fill="url(#bs-a)" fill-rule="evenodd" d="m104 .827 461 369-284 58z"
                          transform="translate(0 -112.827)" opacity=".7" />
                      </svg>
                    </div>
                    <!-- Radial gradient -->
                    <!-- <div
                      class="absolute flex items-center justify-center bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
                      aria-hidden="true">
                      <div class="absolute inset-0 translate-z-0 bg-sky-400 rounded-full blur-[120px] opacity-70"></div>
                      <div class="absolute w-1/4 h-1/4 translate-z-0 bg-blue-400 rounded-full blur-[40px]"></div>
                    </div> -->
                    <div class=" absolute inset-0 -z-10 -mx-28 rounded-t-[3rem] pointer-events-none overflow-hidden"
                      aria-hidden="true">
                      <div class="absolute left-1/2 -translate-x-1/2 top-0 -z-10">
                        <img src="@/assets/images/stellar/features-bg.svg" class="max-w-none" width="1404" height="658"
                          alt="Features Illustration">
                      </div>
                    </div>

                    <!-- Text -->
                    <div class=" lg:w-1/2 w-full shrink-0  md:order-none p-6 pt-3 md:p-8  lg:pr-0">
                      <div class="mb-3">
                        <div>
                          <h3
                            class="inline-flex text-3xl font-bold bg-clip-text text-white bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                            {{ $t('features2_box1_title') }}</h3>
                          <p class="text-slate-400 text-xl font-normal">{{ $t('features2_box1_text') }}
                          </p>
                        </div>
                      </div>
                      <div class="">
                        <div
                          class="lg:w-1/2 min-h-32 h-auto p-3 bg-gray-200 rounded flex justify-between lg:fixed lg:-left-4">
                          <div>
                            <h3 class="font-inter md:text-lg/normal text-sm/normal text-zinc-900 font-bold ">Building
                              {{ $t('features2_box1_title2') }}</h3>
                            <p class="font-inter md:text-lg/normal text-sm/normal text-stone-500 font-normal ">
                              {{ $t('features2_box1_text2') }}</p>
                          </div>
                          <img src="@/assets/images/iso-logo.png" class="w-11 h-11" />
                        </div>
                        <!-- <a class="btn-sm text-slate-300 hover:text-white transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.900),_theme(colors.slate.900))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-full before:pointer-events-none"
                          href="#0">
                          <span class="relative inline-flex items-center">
                            Learn more <span
                              class="tracking-normal text-cyan-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon icon="fa-solid fa-arrow-right" /></span>
                          </span>
                        </a> -->
                      </div>
                    </div>
                    <!-- Image -->
                    <div class=" lg:relative w-full lg:w-1/2 lg:min-h-84 lg:h-96 md:h-[500px] h-auto block mb-3 ">
                      <img class="lg:fixed lg:-top-14 lg:right-0 z-[9999999] md:ml-auto mx-auto md:max-w-none    "
                        src="@/assets/images/features/feature-base-avatar.png" width="504" height="400"
                        alt="Feature 01">
                      <!-- <img class="md:fixed md:-top-14 md:right-0 z-[9999999] md:ml-auto mx-auto max-w-none   "
                        src="@/assets/images/features/feature-base.png" width="504" height="400" alt="Feature 01">
                      <img
                        class="md:fixed md:-bottom-5 md:top-5 md:right-0 z-[9999999] md:ml-auto mx-auto max-w-none fixed top-2/4  "
                        src="@/assets/images/features/feature-avatars.png" width="504" height="400" alt="Feature 01"> -->
                    </div>

                  </div>
                </div>
              </HighlighterItemComponent>
            </div>
            <!-- Box #2 -->
            <div class="md:col-span-7" data-aos="fade-down">
              <HighlighterItemComponent>
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <div class="flex flex-col">
                    <!-- Radial gradient -->
                    <div
                      class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                      <div class="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                    </div>
                    <!-- Text -->
                    <div class=" shrink-0  md:order-none p-6 pt-4 md:p-8">
                      <div>
                        <h3
                          class="inline-flex text-xl/normal font-bold bg-clip-text text-slate-50 bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                          {{ $t('features2_box2_col1_title') }}</h3>
                        <p class="text-slate-400 font-normal text-base">{{ $t('features2_box2_col1_text') }}</p>
                      </div>
                    </div>
                    <!-- Image -->
                    <div class="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                      <img
                        class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0 md:w-11/12"
                        src="@/assets/images/features/feature-chart.png" width="536" height="230" alt="Feature 02">
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
            </div>
            <!-- Box #3 -->
            <div class="md:col-span-5" data-aos="fade-down">
              <HighlighterItemComponent>
                <div class="relative h-full bg-slate-900 rounded-[inherit] z-20 overflow-hidden">
                  <div class="flex flex-col">
                    <!-- Radial gradient -->
                    <div
                      class="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                      <div class="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                    </div>
                    <!-- Text -->
                    <div class="shrink-0 order-1 md:order-none p-6 pt-4 md:p-8">
                      <div>
                        <h3
                          class="inline-flex text-xl/normal font-bold bg-clip-text text-slate-50 bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-1">
                          {{ $t('features2_box2_col2_title') }}</h3>
                        <p class="text-slate-400 font-normal text-base">{{ $t('features2_box2_col2_text') }}</p>
                      </div>
                    </div>
                    <!-- Image -->
                    <div class="relative w-full h-64 md:h-auto overflow-hidden md:pb-8">
                      <img
                        class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0 md:w-11/12 md:aspect-square"
                        src="@/assets/images/features/feature-github.png" width="230" height="230" alt="Feature 03">
                      <!-- <img
                        class="absolute bottom-0 left-1/2 -translate-x-1/2 mx-auto max-w-none md:max-w-full md:relative md:left-0 md:translate-x-0 "
                        src="@/assets/images/features/feature-image-03.png" width="230" height="230" alt="Feature 03"> -->
                    </div>
                  </div>
                </div>
              </HighlighterItemComponent>
            </div>
          </HighlighterComponent>
        </div>
        <!-- Features list -->
        <div class="grid md:grid-cols-3 gap-8 md:gap-12">

          <!-- Feature -->
          <div v-for="(feature_item, index) in $tm('features2_list')" :key="index">
            <div class="flex items-center space-x-2 mb-1">
              <span v-html="feature_item.icon"></span>
              <h4 class="font-medium text-slate-50">{{ feature_item.title }}</h4>
            </div>
            <p class="text-sm text-slate-400">{{ feature_item.text }}</p>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ParticlesComponent from './ParticlesComponent.vue'
import HighlighterComponent from './HighlighterComponent.vue'
import HighlighterItemComponent from './HighlighterItemComponent.vue'

export default {
  name: 'FeaturesSection02',
  components: {
    ParticlesComponent,
    HighlighterComponent,
    HighlighterItemComponent,
  },
  data() {
    return {
      // features_list: this.$i18n.messages[this.$i18n.locale].features2_list


    }
  },
  mounted() {
    console.log("mounted")
    // this.features_list = this.$i18n.messages[this.$i18n.locale].features2_list

  },

  watch: {
    '$i18n.locale'(newval) {
      console.log("watch", newval)
      this.features_list = this.$t('features2_list')
    },
  }

  /** */
}
</script>